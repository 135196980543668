import { Component, inject, input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonModule } from '../button/button.module'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { ModalService } from 'src/app/core/services/modal.service'
import { EventService } from 'src/app/core/services/event.service'
import { ComparisonCardComponent } from './comparison-card.component'
import { GlobalDataService } from 'src/app/core/services/global-data.service'
import { ComparisonService } from 'src/app/core/services/comparison.service'

@Component({
  selector: 'app-comparison-banner',
  template: `
    <div class="banner">
      <div class="info" i18n="@@comparisonBanner_InfoLabel">Add up to 3 assets to compare</div>
      <div class="banner-container">
        <div class="flex">
          <div class="asset-condensed-container">
            @for (asset of comparisonService.assets(); track $index) {
              <app-comparison-card
                [asset]="asset"
                [defaultImage]="globalDataService.defaultImage()"
                [currencyCode]="globalDataService.selectedCurrency().code"
                (clearClicked)="clearAsset(asset)"
              ></app-comparison-card>
            }
          </div>
          <div class="actions">
            <app-button btn-medium btn-secondary btn-outlined (click)="clearAll()" i18n="@@general_ClearAllButton">Clear all</app-button>
            <app-button
              btn-medium
              btn-secondary
              btn-contained
              (click)="compare()"
              [disabled]="comparisonService.assets().length < 2"
              i18n="@@general_CompareButton"
            >Compare</app-button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    @use "sass:map";
    @import "src/assets/_colors";
    @import "src/assets/zindex";
    @import 'src/assets/mixins';

    :host {
      width: 100%;
      height: 200px;
      background-color: map-get($light, ref, white);
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: map-get($z-index-map, modal);
      @include mq(large) {
        height: 130px;
      }
    }

    .banner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .info {
        background-color: var(--theme-comparison-modal-header-bg-color);
        color: var(--theme-comparison-modal-header-font-color);
        text-transform: uppercase;
        font-size: 12px;
        height: 22px;
        text-align: center;
        box-sizing: border-box;
      }

      .banner-container {
        box-sizing: border-box;
        margin: auto;
        width: 100%;
        max-width: 1312px;
        height: 10px;
        flex-grow: 1;
        display: block;

        .flex {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 20px;
          padding: 12px 16px 0;

          @include mq(large) {
            flex-direction: row;
            padding: 24px 32px;
          }
        }

        .asset-condensed-container {
          height: auto;
          display: grid;
          height: 80px;
          grid-template-columns: 1fr 1fr 1fr;

          app-comparison-card:nth-child(1) {
            justify-self: start;
          }

          app-comparison-card:nth-child(2) {
            justify-self: center;
          }

          app-comparison-card:nth-child(3) {
            justify-self: end;
          }

          @include mq(large) {
            width: 100%;
            height: 60px;
            gap: 32px;
            app-comparison-card {
              justify-self: stretch !important;
              width: 100%;
            }
          }
        }

        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;

          app-button {
            --app-button-width: 100%;
            --app-button-flex: 1;
            display: flex;
            flex-grow: 1;
          }

          @include mq(large) {
            flex-shrink: 0;
          }
        }
      }
    }
  `],
  imports: [
    CommonModule,
    ButtonModule,
    ComparisonCardComponent
  ],
})
export class ComparisonBannerComponent {
  protected globalDataService = inject(GlobalDataService)
  protected comparisonService = inject(ComparisonService)
  protected modalService = inject(ModalService)
  private _eventService = inject(EventService)

  compare() {
    if (this.comparisonService.assets().length < 2) {
      return
    }
    this.modalService.comparison({})
  }

  clearAll() {
    this._eventService.notifyClearAllComparisonAssets()
  }

  clearAsset(asset: IAsset) {
    this._eventService.notifyClearComparisonAsset(asset.equipment_number)
  }
}
